
#sign-in-cont {
    margin: 20px;
}

.playlist-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 16px; 
    justify-content: center; 
    margin: auto;
    padding-bottom: 30px;
}

.playlist-card {
    width: 100%;
    max-width: 300px; 
    margin: auto;
}

.welcome-text {
    font-size: 28px;
    text-align: center;
    margin: 20px 0;
    color: #333;
}

