.album-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 32px;
    justify-content: center;
    margin: auto;
    padding-bottom: 30px;
    margin: 20px 35px 20px 20px;
}

.album-card {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.05);
    }
}

.album-title {
    font-size: 1rem;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
}

.album-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    padding-bottom: 10px;
}

.search-bar-container {
    margin: 20px;
    display: flex;
    justify-content: center;
}

