.App {
  text-align: center;
}

.App-header {
  background-color: #878787;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-body {
  background-color: rgb(110, 118, 118);
  width: 100%;
  min-height: 100vh;
  height: auto;
}

.App-body p {
  text-align: left;
  padding: 0px 0px 0px 20px;
}

.Login_card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.Login_button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1DB954;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Login_button:hover {
  background-color: #0E6027;
}
