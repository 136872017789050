#feedType {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

/* Adds vertical line before "Friends are listening to" */
#verticalLine {
  border-left: solid white;
}

.feedOption {
  color: white;
  padding: 10px;
  margin: 0;
  margin-bottom: 50px;
}

.song-image {
  transition: transform 0.2s; 
  width: 350px;
}

.song-image:hover {
  transform: scale(1.1);
}

.song-card {
    height: 600px;
    width: 400px;
}

